import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { Form, Formik } from 'formik';
import { Link, navigate } from 'gatsby';
import Button from '../common/Button';
import { FormInput, Label } from '../common/FormInput';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { removeCookie, setCookie } from '../../util/cookie';
import { applyToEvent, getEvent } from './api';
import ApplyToEventBlock from './ApplyToEventBlock';
import ReCAPTCHA from 'react-google-recaptcha';
import { checkEventApplicationConfigsForRegister } from '../../helpers/eventApplication.helper';

const SignupSchema = Yup.object().shape({
  trueEmail: Yup.string()
    .max(100)
    .email('Invalid email')
    .required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  firstName: Yup.string()
    .max(100)
    .required('Required'),
  lastName: Yup.string()
    .max(100)
    .required('Required')
});

enum SignupState {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED
}

interface SignupProps {
  path: string;
}

function getIntended(queryParams: string) {
  const querySplit = location.search.split('?');
  if (querySplit && querySplit[1]) {
    const intended = querySplit[1].split('=');
    if (intended && intended[1]) {
      return querySplit[2] ? intended[1] + `?${querySplit[2]}` : intended[1];
    }
    return '';
  }
  return '';
}

const Signup = (props: SignupProps) => {
  const recaptcha: any = useRef();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const eventId = new URLSearchParams(location.search).get('applyToEvent');
  const [eventToApplyTo, setEventToApplyTo] = useState<any>(null);
  const intended = getIntended(location.search);
  useEffect(() => {
    if (auth.authState) {
      navigate('/members/profile');
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      const loadEvent = async () => {
        const result = await getEvent(eventId);
        setEventToApplyTo(result.event);
      };
      loadEvent();
    }
  }, [eventId]);

  const [signupState, setSignupState] = useState(SignupState.IDLE);
  const [signupFailureMessage, setSignupFailureMessage] = useState<any>(null);
  const submitSignup = async (values: any) => {
    try {
      //force logout first
      removeCookie('access_token');
      auth.setAuthState(null);
      const captchaValue = recaptcha?.current?.getValue();
      console.log(captchaValue);
      if (!captchaValue) {
        setSignupState(SignupState.FAILED);
        setSignupFailureMessage(
          'Invalid captcha, please check the captcha box.'
        );
        return;
      }
      //hp guard
      if (values.email || values.phone) {
        return;
      }

      setSignupState(SignupState.LOADING);

      const response = await fetch(`${process.env.GATSBY_API_URL}/signUp`, {
        method: 'POST',
        body: JSON.stringify({ ...values, email: values.trueEmail }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const body = await response.json();
      if (!response.ok) {
        const { state, message } = body;

        if (state === 'ERROR') {
          setSignupFailureMessage(message);
        }
        setSignupState(SignupState.FAILED);
      } else {
        //log created user in
        setCookie('access_token', body.data.access_token);
        setSignupState(SignupState.SUCCEEDED);
        auth.setAuthState(body.data);
        if (eventToApplyTo) {
          const {
            eventApplicationId,
            isApplyNowAvailable,
            isContinueAvailable
          } = await checkEventApplicationConfigsForRegister(eventToApplyTo.id);

          if (isApplyNowAvailable) {
            const result = await applyToEvent(eventToApplyTo.id);
            navigate(
              `/members/events/applications/${result.data.id}/manage/general-information`
            );
          } else if (isContinueAvailable) {
            navigate(
              `/members/events/applications/${eventApplicationId}/manage/general-information`
            );
          } else {
            navigate(`/members/events/applications`);
          }

          return;
        }
        navigate(intended || '/members/profile');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Formik
      initialValues={{
        trueEmail: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        email: '', //hp value
        phone: '' //hp value
      }}
      onSubmit={values => submitSignup(values)}
      validationSchema={SignupSchema}
    >
      {({ errors }) => (
        <Form className="mt-8">
          <>
            {signupState === SignupState.FAILED && (
              <div className="p-4 mb-4 bg-red-100 border border-red-600 rounded-lg">
                <p className="text-red-600 sans-serif">
                  {signupFailureMessage}
                </p>
              </div>
            )}
          </>
          <div>
            {eventId && (
              <ApplyToEventBlock
                eventToApplyTo={eventToApplyTo}
                type="signup"
              />
            )}
            <h2 className="mb-4 text-3xl font-bold text-primary sans-serif">
              Register
            </h2>
            <div className="mb-4">
              <div className="mb-2">
                <Label text="Email" />
              </div>
              <FormInput
                ariaLabel="Email"
                name="trueEmail"
                type="text"
                placeholder="Email"
              />
            </div>

            <div className="flex mt-8">
              <div className="w-1/2 mb-4 mr-2">
                <div className="mb-2">
                  <Label text="First Name" />
                </div>
                <FormInput
                  ariaLabel="First Name"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="w-1/2 ml-2">
                <div className="mb-2">
                  <Label text="Last Name" />
                </div>
                <FormInput
                  ariaLabel="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-1/2 mb-4 mr-2">
                <div className="mb-2">
                  <Label text="Password" />
                </div>
                <FormInput
                  ariaLabel="Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              <div className="w-1/2 ml-2">
                <div className="mb-2">
                  <Label text="Confirm Password" />
                </div>
                <FormInput
                  ariaLabel="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            <div //hp value
              style={{
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                height: 0,
                width: 0,
                zIndex: -1
              }}
            >
              <div className="mb-2">
                <Label text="Email" />
              </div>
              <FormInput
                ariaLabel="Email"
                name="email"
                type="text"
                placeholder="Email"
                tabIndex={-1}
              />
            </div>
            <div //hp value
              style={{
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                height: 0,
                width: 0,
                zIndex: -1
              }}
            >
              <div className="mb-2">
                <Label text="Phone" />
              </div>

              <FormInput
                ariaLabel="Phone"
                name="Phone"
                type="tel"
                placeholder="Phone"
                tabIndex={-1}
              />
            </div>
          </div>
          <ReCAPTCHA
            ref={recaptcha}
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          />
          <div className="mt-6">
            <Button
              type="submit"
              text="Sign Up"
              isLoading={signupState === SignupState.LOADING}
              disabled={!!errors.email || !!errors.password}
            />
            <Link
              to={`/auth/login${
                eventToApplyTo ? `?applyToEvent=${eventToApplyTo.id}` : ''
              }`}
              className="ml-6 text-primary sans-serif hover:underline hover:text-primary-dark"
            >
              &larr; Log In
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Signup;
